import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid2";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { theme } from "../definitions/theme";

const backgroundColor = theme.palette.primary.main;
const textColor = theme.palette.text.secondary;

const navbarHeight = 150;
const navItems = [
  { text: "Home", target: "/#" },
  { text: "Angebot", target: "/#portfolio" },
  { text: "Vita", target: "/#vita" },
  /*   { text: "Impressum", target: "/imprint#" }, */
];

export function NavBar() {
  return (
    <>
      <Box
        id="home"
        sx={{
          display: "flex",
          minHeight: navbarHeight,
          height: navbarHeight,
        }}
      >
        <CssBaseline />
        <AppBar component="nav" position="static">
          <Toolbar
            sx={{
              background: { backgroundColor },
              minHeight: navbarHeight,
              height: navbarHeight,
            }}
          >
            <Grid
              container
              sx={{
                flexGrow: 1,
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Grid
                size={4}
                sx={{
                  display: { xs: "none", sm: "flex" },
                }}
              >
                <Typography
                  component="div"
                  sx={{ flexGrow: 1, fontSize: 27, color: textColor }}
                >
                  Beratung &<br />
                  Professionalisierung
                </Typography>
              </Grid>
              <Grid
                component={Box}
                size={4}
                sx={{
                  display: { xs: "flex", sm: "block" },
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <Button
                  key={"DominikElsbroek"}
                  to={"/#"}
                  component={HashLink}
                  sx={{
                    color: textColor,
                    textTransform: "none",
                    display: { xs: "flex", sm: "block" },
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: textColor,
                      textAlign: "center",
                      fontSize: 42,
                      fontWeight: 500,
                    }}
                  >
                    Dominik&nbsp;Elsbroek
                  </Typography>
                </Button>
              </Grid>
              <Grid
                size={4}
                sx={{
                  display: { xs: "none", sm: "flex" },
                  justifyContent: "flex-end",
                }}
              >
                <Box
                  sx={{
                    justifyContent: "flex-end",
                    alignContent: "center",
                  }}
                >
                  {navItems.map((item) => (
                    <Button
                      key={item.text}
                      component={
                        item.target.includes("#") ? HashLink : RouterLink
                      }
                      to={item.target}
                      sx={{ color: textColor }}
                    >
                      {item.text}
                    </Button>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}
